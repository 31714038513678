import React, {Component} from 'react';

import {
    Route,
    Switch,
    Redirect,
    withRouter
} from "react-router-dom"

import Footer from "./views/Footer";
import Header from "./views/Header";
import Sidebar from "./views/Sidebar";
import Dashboard from "./views/pages/Dashboard";
import Offer from "./views/pages/Offer";
import Offers from "./views/pages/Offers";
import TrafficSources from "./views/pages/TrafficSources";
import Stats from "./views/pages/Stats";
import Webix from "./views/pages/Webix";
import Settings from "./views/pages/Settings";
import Lss from "./views/pages/Lss";
import Terms from "./views/pages/static/Terms";
import Privacy from "./views/pages/static/Privacy";
import Help from "./views/pages/static/Help";
import Support from "./views/pages/static/Support";
import ajax from "./data/ajax";
import Loading from "./views/components/Loading";
import Intercom from "./views/components/Intercom";
import ScrollToTop from "./views/components/ScrollToTop";
import Login from "./views/pages/Login";
import Payments from "./views/pages/Payments";
import Logout from "./views/pages/Logout";
import Develop from "./views/pages/Develop";
import Rates from "./views/pages/Rates";
import RegisterRecaptcha from "./views/pages/RegisterRecaptcha";
import GTag from "./views/components/GTag";

class App extends Component {
    constructor(props) {
        super(props)
        // this.state = {
        //     error: null,
        //     isLoaded: false,
        //     items: [],
        //     yesterday: {
        //         conversions: 0,
        //         visitors: 0,
        //         conversionRate: 0,
        //         earnings: 0,
        //     },
        //     today: {
        //         conversions: 0,
        //         visitors: 0,
        //         conversionRate: 0,
        //         earnings: 0,
        //     },
        // }

        this.state = {
            error: null,
            isLoaded: false,
            isAuth: true,
            user: {},
        }
    }

    componentDidMount() {
        const url = window.trackerBaseUrl + "auth";

        fetch(url, {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + ajax.getToken()
            },
        })
            .then(res => res.json())
            .then(
                result => {
                    // console.log("result")
                    // console.log(result)

                    if (!result) {
                        this.setState({
                            isLoaded: true,
                            isAuth: false,
                        })
                    } else {
                        this.setState({
                            isLoaded: true,
                            isAuth: true,
                            user: result,
                        })
                    }
                },
                error => {
                    this.setState({
                        isLoaded: true,
                        error: error,
                    })
                }
            )
    }

    render() {
        const {history} = this.props
        const {error, isLoaded, isAuth} = this.state
        if (error) {
            return <div>Ошибка: {error.message}</div>
        } else if (!isLoaded) {
            return <Loading/>
        } else if (!isAuth) {
            return (
                <>
                    <GTag/>
                    <Switch>
                        <Route history={history} path='/login' component={Login}/>
                        <Route history={history} path='/signup' component={RegisterRecaptcha}/>
                        <Redirect from='/' to='/login'/>
                    </Switch>
                </>
            )
        } else {
            const userName = (this.state.user.first_name + " " + this.state.user.last_name).trim();

            return (
                <div className="wrapper">
                    <GTag/>
                    <Sidebar/>

                    <div className="main">
                        <Header userName={userName.length > 0 ? userName : this.state.user.email}/>

                        <main className="content">
                            <ScrollToTop/>
                            {
                                process.env.REACT_APP_INTERCOM_ID &&
                                <Intercom
                                    appId={process.env.REACT_APP_INTERCOM_ID}
                                    email={this.state.user.email}
                                    userId={this.state.user.id}
                                    createdAt={this.state.user.created_at}
                                />
                            }
                            {
                                process.env.REACT_APP_TYPE === "lss" &&
                                <Switch>
                                    <Route history={history} path='/stats'
                                           render={(props) => (
                                               <Lss {...props} user={this.state.user}/>
                                           )}
                                    />
                                    <Route history={history} path='/rates' component={Rates}/>
                                    <Route history={history} path='/settings' component={Settings}/>
                                    <Route history={history} path='/dev' component={Develop}/>
                                    <Route history={history} path='/logout' component={Logout}/>
                                    <Redirect from='/' to='/stats'/>
                                </Switch>
                            }
                            {
                                process.env.REACT_APP_TYPE === "cabinet" &&
                                <Switch>
                                    <Route history={history} path='/dashboard' component={Dashboard}/>
                                    <Route history={history} path='/offers' component={Offers}/>
                                    <Route history={history} path='/offer/:offerId' component={Offer}/>
                                    <Route history={history} path='/traffic-sources' component={TrafficSources}/>
                                    <Route history={history} path='/payments' component={Payments}/>
                                    <Route history={history} path='/stats' component={Stats}/>
                                    <Route history={history} path='/a_webix.html' component={Webix}/>
                                    <Route history={history} path='/settings' component={Settings}/>
                                    <Route history={history} path='/terms' component={Terms}/>
                                    <Route history={history} path='/privacy' component={Privacy}/>
                                    <Route history={history} path='/help' component={Help}/>
                                    <Route history={history} path='/support' component={Support}/>

                                    <Route history={history} path='/stats' component={Lss}/>
                                    <Route history={history} path='/rates' component={Rates}/>

                                    <Route history={history} path='/dev' component={Develop}/>
                                    <Route history={history} path='/logout' component={Logout}/>
                                    <Redirect from='/' to='/dashboard'/>
                                </Switch>
                            }
                        </main>

                        <Footer/>
                    </div>
                </div>
            )
        }
    }
}

export default withRouter(App);
