import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Tab from "./Tab";
import {useInput, useInputRadio} from "../../../components/input-hook";
import SelectCountry from "../../components/SelectCountry";
import ButtonSubmit from "../../components/ButtonSubmit";
import {useProfileRequest, useProfileUpdate} from "../../../data/profile";
import AlertSuccess from "../../components/AlertSuccess";

export default function TabAccount({tabActiveID}) {
    const typeIndividual = "1";
    const typeCompany = "2";

    const [{data: profile, isLoading, error}] = useProfileRequest([]);
    const [{
        data: updateProfile,
        setRequestData,
        isLoading: updateIsLoading,
        error: updateError,
        dataErrors
    }] = useProfileUpdate(null);

    const {
        value: type,
        setValue: setType,
        error: errorType,
        setError: setErrorType,
        bind: bindType
    } = useInputRadio(typeIndividual);
    const {
        value: companyName,
        setValue: setCompanyName,
        error: errorCompanyName,
        setError: setErrorCompanyName,
        bind: bindCompanyName
    } = useInput('');
    const {
        value: firstName,
        setValue: setFirstName,
        error: errorFirstName,
        setError: setErrorFirstName,
        bind: bindFirstName
    } = useInput('');
    const {
        value: lastName,
        setValue: setLastName,
        error: errorLastName,
        setError: setErrorLastName,
        bind: bindLastName
    } = useInput('');
    const {
        value: email,
        setValue: setEmail,
        error: errorEmail,
        setError: setErrorEmail,
        bind: bindEmail
    } = useInput('');
    const {
        value: messenger,
        setValue: setMessenger,
        error: errorMessenger,
        setError: setErrorMessenger,
        bind: bindMessenger
    } = useInput('');
    const {value: country, setValue: setCountry, error: errorCountry, setError: setErrorCountry} = useInput('');
    const {value: city, setValue: setCity, error: errorCity, setError: setErrorCity, bind: bindCity} = useInput('');
    const {
        value: address1,
        setValue: setAddress1,
        error: errorAddress1,
        setError: setErrorAddress1,
        bind: bindAddress1
    } = useInput('');
    const {
        value: address2,
        setValue: setAddress2,
        error: errorAddress2,
        setError: setErrorAddress2,
        bind: bindAddress2
    } = useInput('');

    const setFromResponse = (result) => {
        setType(result.type ? result.type + '' : typeIndividual);
        setCompanyName(result.company_name ? result.company_name : '');
        setFirstName(result.first_name ? result.first_name : '');
        setLastName(result.last_name ? result.last_name : '');
        setEmail(result.email ? result.email : '');
        setMessenger(result.messenger ? result.messenger : '');
        setCountry(result.country_code ? result.country_code : '');
        setCity(result.city ? result.city : '');
        setAddress1(result.address_1 ? result.address_1 : '');
        setAddress2(result.address_2 ? result.address_2 : '');
    }

    useEffect(() => {
        setFromResponse(profile);
    }, [profile]);

    useEffect(() => {
        if (updateProfile) {
            setFromResponse(updateProfile);
        }
    }, [updateProfile]);

    useEffect(() => {
        let errors = {
            type: '',
            company_name: '',
            first_name: '',
            last_name: '',
            email: '',
            messenger: '',
            country_code: '',
            city: '',
            address_1: '',
            address_2: '',
        };
        dataErrors.forEach(v => {
            if (v.field === 'type') {
                errors.type = v.message
            } else if (v.field === 'company_name') {
                errors.company_name = v.message
            } else if (v.field === 'first_name') {
                errors.first_name = v.message
            } else if (v.field === 'last_name') {
                errors.last_name = v.message
            } else if (v.field === 'email') {
                errors.email = v.message
            } else if (v.field === 'messenger') {
                errors.messenger = v.message
            } else if (v.field === 'country_code') {
                errors.country_code = v.message
            } else if (v.field === 'city') {
                errors.city = v.message
            } else if (v.field === 'address_1') {
                errors.address_1 = v.message
            } else if (v.field === 'address_2') {
                errors.address_2 = v.message
            }
        })

        setErrorType(errors.type);
        setErrorCompanyName(errors.company_name);
        setErrorFirstName(errors.first_name);
        setErrorLastName(errors.last_name);
        setErrorEmail(errors.email);
        setErrorMessenger(errors.messenger);
        setErrorCountry(errors.country_code);
        setErrorCity(errors.city);
        setErrorAddress1(errors.address_1);
        setErrorAddress2(errors.address_2);
    }, [dataErrors]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            type: type,
            company_name: type === typeCompany ? companyName : '',
            first_name: firstName,
            last_name: lastName,
            email: email,
            messenger: messenger,
            country_code: country,
            city: city,
            address_1: address1,
            address_2: address2,
        };
        setRequestData(data);
    }

    return (
        <Tab tabID="account" tabActiveID={tabActiveID} isLoading={isLoading} error={error} errorUpdate={updateError}>
            <form onSubmit={handleSubmit}>
                {
                    updateProfile && <AlertSuccess message="Data updated successfully"/>
                }

                <div className="form-group">
                    <label htmlFor="radioCompany">Are you Company or Individual?</label>

                    <div className="col-sm-10">
                        <div className="custom-controls-stacked is-invalid">
                            <label className="custom-control custom-radio">
                                <input type="radio"
                                       name="custom-radio-3"
                                       className="custom-control-input"
                                       {...bindType(typeIndividual)}
                                />
                                <span className="custom-control-label">Individual</span>
                            </label>
                            <label className="custom-control custom-radio is-invalid">
                                <input type="radio"
                                       name="custom-radio-3"
                                       className="custom-control-input"
                                       {...bindType(typeCompany)}
                                />
                                <span className="custom-control-label">Company</span>
                            </label>
                            {errorType &&
                            <div className="invalid-feedback" style={{display: "block"}}>{errorType}</div>}
                        </div>
                    </div>
                </div>

                {
                    type === typeCompany &&
                    <div className="form-group">
                        <label htmlFor="inputCompany">Company Name</label>
                        <input type="text" id="inputCompany"
                               className={"form-control " + (errorCompanyName.length > 0 ? "is-invalid" : '')}
                               placeholder="First name" {...bindCompanyName}/>
                        <div className="invalid-feedback">{errorFirstName}</div>
                    </div>
                }

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="inputFirstName">First name</label>
                        <input type="text" id="inputFirstName"
                               className={"form-control " + (errorFirstName.length > 0 ? "is-invalid" : '')}
                               placeholder="First name" {...bindFirstName}/>
                        <div className="invalid-feedback">{errorFirstName}</div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputLastName">Last name</label>
                        <input type="text" id="inputLastName"
                               className={"form-control " + (errorLastName.length > 0 ? "is-invalid" : '')}
                               placeholder="Last name" {...bindLastName}/>
                        <div className="invalid-feedback">{errorLastName}</div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="inputEmail4">Email</label>
                        <input type="email" id="inputEmail4"
                               className={"form-control " + (errorEmail.length > 0 ? "is-invalid" : '')}
                               placeholder="Email" {...bindEmail}/>
                        <div className="invalid-feedback">{errorEmail}</div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputMessenger">Messenger/nickname</label>
                        <input type="text" id="inputMessenger"
                               className={"form-control " + (errorMessenger.length > 0 ? "is-invalid" : '')}
                               {...bindMessenger}/>
                        <div className="invalid-feedback">{errorMessenger}</div>
                    </div>
                </div>


                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label>Country</label> {/* htmlFor="inputCountry" */}
                        {/*<select id="inputCountry" className="form-control">
                            <option selected="">Choose...</option>
                            <option>...</option>
                        </select>*/}

                        <SelectCountry value={country} updateValue={v => setCountry(v)}/>
                        {
                            errorCountry &&
                            <div className="invalid-feedback" style={{display: "block"}}>{errorCountry}</div>
                        }
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputCity">City</label>
                        <input type="text" id="inputCity"
                               className={"form-control " + (errorCity.length > 0 ? "is-invalid" : '')}
                               {...bindCity}/>
                        <div className="invalid-feedback">{errorCity}</div>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="inputAddress">Address</label>
                    <input type="text" id="inputAddress"
                           placeholder="1234 Main St"
                           className={"form-control " + (errorAddress1.length > 0 ? "is-invalid" : '')}
                           {...bindAddress1}/>
                    <div className="invalid-feedback">{errorAddress1}</div>
                </div>

                <div className="form-group">
                    <label htmlFor="inputAddress2">Address 2</label>
                    <input type="text" id="inputAddress2"
                           placeholder="Apartment, studio, or floor"
                           className={"form-control " + (errorAddress2.length > 0 ? "is-invalid" : '')}
                           {...bindAddress2}/>
                    <div className="invalid-feedback">{errorAddress2}</div>
                </div>

                <ButtonSubmit isLoading={updateIsLoading} text="Save changes"/>
            </form>
        </Tab>
    )
}

TabAccount.propTypes = {
    tabActiveID: PropTypes.string.isRequired,
}
