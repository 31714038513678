import React from 'react';

import TableView from "../webix/TableView";

function Webix() {
    return (
        <div className="container-fluid p-0">

            <h1 className="h3 mb-3">Webix table</h1>

            <TableView data={[
                {name: "aaaa", email: "aaaa@gmail.com", age:20},
                {name: "bbbb", email: "bbbb@gmail.com", age:21},
                {name: "cccc", email: "cccc@gmail.com", age:22},
            ]}/>

            <hr/>

        </div>
    );
}

export default Webix;
