import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ajax from "../../data/ajax";
import Container from "../components/Container";
import Card from "../components/Сard";
import Table from "../components/Table";
import TableParams from "./Rates/TableParams";

function Rates() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [error] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get(
                window.trackerBaseUrl + "rates/lss",
                {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + ajax.getToken(),
                    },
                }
            );

            setIsLoaded(true);
            setData(result.data);
        };

        fetchData();
    }, []);

    const [{
        columns: tableColumns,
        initialState: tableInitialState,
    }] = TableParams();

    return (
        <Container title={"Rates"} isLoaded={isLoaded} error={error}>
            <Card>
                <Table columns={tableColumns} data={data} initialState={tableInitialState}/>
            </Card>
        </Container>
    );
}

export default Rates;