import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function Intercom({appId, email, userId, createdAt}) {
    const {pathname} = useLocation();

    useEffect(() => {
        window.Intercom('boot', {
            app_id: appId,
            email: email,
            user_id: userId,
            created_at: createdAt,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.Intercom("update", {last_request_at: parseInt((new Date()).getTime() / 1000)})
    }, [pathname]);

    return null;
}