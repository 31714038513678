import React, {useEffect} from 'react';
import {NavLink, useLocation, withRouter} from 'react-router-dom'
import $ from 'jquery';
import SimpleBar from "simplebar-react";
import SidebarItems from "./SidebarItems";

import 'simplebar/dist/simplebar.min.css';

function Sidebar() {
    const location = useLocation();

    useEffect(() => {
        $(".sidebar-toggle").on("click", function (e) {
            e.preventDefault();
            $(".sidebar")
                .toggleClass("collapsed")
                // Triger resize after animation
                .one("transitionend", function () {
                    setTimeout(function () {
                        window.dispatchEvent(new Event("resize"));
                    }, 100);
                });
        });
    }, []);

    const getNavLinkClass = (path) => {
        // console.log(location);
        return location.pathname === path ? 'active' : '';
    }

    const navLinkClick = () => {
        // node_modules/bootstrap/scss/_variables.scss $grid-breakpoints
        if (window.matchMedia('(max-width: 992px)').matches) {
            $(".sidebar-toggle").trigger("click");
        }
    }

    const menuItems = SidebarItems();

    return (
        <nav id="sidebar" className="sidebar">
            <SimpleBar className="sidebar-content">
                <a className="sidebar-brand" href="/index.html">
                    <span className="align-middle">A2Tag.Com</span>
                </a>

                <ul className="sidebar-nav">
                    {menuItems.map((item, i) => {
                        return (
                            <li className={"sidebar-item " + getNavLinkClass("/" + item.link)} key={i}>
                                <NavLink className="sidebar-link" to={"/" + item.link} onClick={navLinkClick}>
                                    {item.icon} <span className="align-middle">{item.title}</span>
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
            </SimpleBar>
        </nav>
    )
}

export default withRouter(Sidebar);
