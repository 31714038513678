import React from 'react';
import * as Icon from 'react-feather';

const SidebarItems = () => {
    const lssItems = [
        {
            title: 'Statistic',
            link: 'stats',
            icon: <Icon.BarChart2/>,
        },
        {
            title: 'Rates',
            link: 'rates',
            icon: <Icon.Activity/>,
        },
        {
            title: 'Settings',
            link: 'settings',
            icon: <Icon.User/>,
        },
    ];

    const cabinetItems = [
        {
            title: 'Dashboard',
            link: 'dashboard',
            icon: <Icon.Sliders/>,
        },
        {
            title: 'Statistic',
            link: 'stats',
            icon: <Icon.BarChart2/>,
        },
        {
            title: 'Offers',
            link: 'offers',
            icon: <Icon.List/>,
        },
        {
            title: 'Traffic Sources',
            link: 'traffic-sources',
            icon: <Icon.Layers/>,
        },
        {
            title: 'Payments',
            link: 'payments',
            icon: <Icon.CreditCard/>,
        },
        {
            title: 'Profile',
            link: 'profile',
            icon: <Icon.User/>,
        },
        {
            title: 'Settings',
            link: 'settings',
            icon: <Icon.User/>,
        },
    ];

    let items = process.env.REACT_APP_TYPE === "lss" ? lssItems : cabinetItems;

    if (process.env.NODE_ENV === "development") {
        items.push(
            {
                title: 'Development',
                link: 'dev',
                icon: <Icon.Command/>,
            })
    }

    return items;
}

export default SidebarItems;
