import React from 'react';
import {Link} from "react-router-dom";

export default function Header({userName}) {
    return (
        <nav className="navbar navbar-expand navbar-light navbar-bg">
            <a href="/index.html" className="sidebar-toggle d-flex">
                <i className="hamburger align-self-center" />
            </a>

            <div className="navbar-collapse collapse">
                <ul className="navbar-nav navbar-align">
                    <li className="nav-item dropdown">
                        <div className="dropdown-menu-header" style={{borderBottom: 0}}>
                            <div className="position-relative">
                                {/*
                                14:03 (EST)
                                https://www.npmjs.com/package/react-moment
                                */}
                                <Link to="/logout">Logout ({userName})</Link>
                            </div>
                        </div>
                    </li>
                    {
                        process.env.REACT_APP_TYPE === "cabinet" &&
                        <li className="nav-item dropdown">
                            <a className="nav-icon dropdown-toggle d-inline-block d-sm-none" href="/index.html"
                               data-toggle="dropdown">
                                <i className="align-middle" data-feather="settings" />
                            </a>
                            <a className="nav-link dropdown-toggle d-none d-sm-inline-block" href="/index.html"
                               data-toggle="dropdown">
                                <img src="https://dummyimage.com/200x200/aaa/000"
                                     className="avatar img-fluid rounded mr-1" alt="Charles Hall"/>
                                <span className="text-dark">User Name</span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <a className="dropdown-item" href="/index.html">
                                    <i className="align-middle mr-1" data-feather="user" /> Profile
                                </a>
                                <div className="dropdown-divider" />
                                <a className="dropdown-item" href="/index.html">Log out</a>
                            </div>
                        </li>
                    }
                </ul>
            </div>
        </nav>
    );
}
