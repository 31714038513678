import React, {useState, useEffect} from 'react';
import Container from "../components/Container";
import Card from "../components/Сard";
import Dump from "../components/Dump";
import {useProfileRequest} from "../../data/profile";

function Develop() {
    const [{data: profile, isLoading, error}] = useProfileRequest([]);

    useEffect(() => {

    }, []);

    return (
        <Container title={"Develop"} isLoaded={!isLoading} error={error}>
            <Card>
                <div className="row">
                    <div className="col-md-12">
                        <Dump value={profile}/>
                    </div>
                </div>
            </Card>
        </Container>
    );
}

export default Develop;