import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

import {consoleLog} from "../../components/console";
import {useInput} from "../../components/input-hook";
import GuestContainer from "../components/GuestContainer";
import ButtonSubmit from "../components/ButtonSubmit";

export default function Register() {
    const {value: email, bind: bindEmail} = useInput('');
    const {value: password, bind: bindPassword} = useInput('');
    const {value: messenger, bind: bindMessenger} = useInput('');
    const {value: agree, bind: bindAgree} = useInput(false);
    const [reCaptcha, setReCaptcha] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const {executeRecaptcha} = useGoogleReCaptcha();

    useEffect(() => {
        async function fetchRecaptcha() {
            const result = await executeRecaptcha("signup");
            setReCaptcha(result)
        }

        fetchRecaptcha().then(() => {
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true)

        const url = window.trackerBaseUrl + "register";
        fetch(url, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
                messenger: messenger,
                recaptcha: reCaptcha,
            })
        })
            .then(res => res.json())
            .then(
                result => {
                    consoleLog("result")
                    consoleLog(result)
                    if (result.token) {
                        localStorage.setItem("token", result.token);
                        document.location.reload();
                    } else {
                        alert("signup errors: " + result.join(", "))
                    }
                    setIsLoading(false);
                },
                err => {
                    consoleLog("error")
                    consoleLog(err)
                    setError(err)
                    consoleLog(error)
                    setIsLoading(false);
                }
            )
    }

    const header = <>
        Create account or <Link to='/login'>login</Link>
    </>

    return (
        <GuestContainer header={header}>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="inputRegisterEmail">Email</label>
                    <input
                        id="inputRegisterEmail"
                        type="email"
                        className="form-control form-control-lg"
                        placeholder="Enter your email"
                        {...bindEmail}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="inputRegisterPassword">Password</label>
                    <input
                        id="inputRegisterPassword"
                        type="password"
                        className="form-control form-control-lg"
                        placeholder="Enter your password"
                        {...bindPassword}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="inputRegisterMessenger">Messenger/nickname</label>
                    <input
                        id="inputRegisterMessenger"
                        type="text"
                        className="form-control form-control-lg"
                        {...bindMessenger}
                    />
                </div>
                <div>
                    <input
                        id="inputRegisterAgreeTerms"
                        type="checkbox"
                        {...bindAgree}
                    />
                    <label className="text-small" htmlFor="inputRegisterAgreeTerms">
                        &emsp;Agree with&ensp;
                        <a href={process.env.REACT_APP_OWNER_DOMAIN + '/terms-of-use/'}
                           target="_blank"
                           rel="noopener noreferrer"
                        >our terms</a>
                    </label>
                </div>

                <div className="text-center mt-3">
                    <ButtonSubmit
                        className="btn-lg btn-primary"
                        isLoading={isLoading}
                        disabled={!agree || reCaptcha.length === 0}
                        text="Sign up"/>
                </div>
            </form>
        </GuestContainer>
    )
}
