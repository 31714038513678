import React from 'react';

import ajax from "../../data/ajax"
import Loading from "../components/Loading";

class Offers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
        }
    }

    componentDidMount() {
        // Promise.all([
        //     fetch("http://localhost:3000/items/get1"),
        //     fetch("http://localhost:3000/items/get2"),
        //     fetch("http://localhost:3000/items/get3")
        // ]).then(allResponses => {
        //     const response1 = allResponses[0]
        //     const response2 = allResponses[1]
        //     const response3 = allResponses[2]
        //
        // ...
        //
        // })

        const url = window.trackerBaseUrl + "offers?fields=id,name&expand=groupName"

        fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            // method: 'POST', // *GET, POST, PUT, DELETE, etc.
            // mode: 'cors', // no-cors, *cors, same-origin
            // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + ajax.getToken(),
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            // redirect: 'follow', // manual, *follow, error
            // referrerPolicy: 'no-referrer', // no-referrer, *client
            // body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
            .then(res => res.json())
            .then(
                result => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    })
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                error => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }

    render() {
        const {error, isLoaded, items} = this.state
        if (error) {
            return <div>Ошибка: {error.message}</div>
        } else if (!isLoaded) {
            return <Loading/>
        } else {
            return (
                <div className="container-fluid p-0">
                    <h1 className="h3 mb-3">Offers</h1>

                    <div className="card">
                        <div className="card-body">
                            {
                                false &&
                                <div className="row">
                                    <div className="col-md-6 text-center">
                                        <select className="form-control mb-3">
                                            <option selected>Country</option>
                                            <option>One</option>
                                            <option>Two</option>
                                            <option>Three</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 text-center">
                                        <select className="form-control mb-3">
                                            <option selected>Offers</option>
                                            <option>One</option>
                                            <option>Two</option>
                                            <option>Three</option>
                                        </select>
                                    </div>
                                </div>
                            }
                            {
                                false &&
                                <div className="row">
                                    <div className="col-md-4 text-center">
                                        <select className="form-control mb-3">
                                            <option selected>Vertical</option>
                                            <option>One</option>
                                            <option>Two</option>
                                            <option>Three</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <select className="form-control mb-3">
                                            <option selected>Flow</option>
                                            <option>One</option>
                                            <option>Two</option>
                                            <option>Three</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <select className="form-control mb-3">
                                            <option selected>Payouts</option>
                                            <option>One</option>
                                            <option>Two</option>
                                            <option>Three</option>
                                        </select>
                                    </div>
                                </div>
                            }
                        </div>

                        <table className="table">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Offer Name</th>
                                <th>Vertical</th>
                                <th>GEOs</th>
                                <th>Flow</th>
                                <th>Payouts</th>
                                <th>Actions</th>
                            </tr>
                            </thead>

                            <tbody>

                            {items.map(item => (
                                <tr>
                                    <td>{item.id}</td>
                                    <td><a href={"offer/" + item.id}>{item.name}</a></td>
                                    <td>{item.groupName}</td>
                                    <td>WW</td>
                                    <td>?? 1-Click</td>
                                    <td>?? Multiple</td>
                                    <td className="table-action"> actions ???
                                        <a href="/index.html"><i className="align-middle" data-feather="eye"></i></a>
                                        <a href="/index.html"><i className="align-middle" data-feather="link"></i></a>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    {
                        false &&
                        <nav aria-label="Page navigation example">
                            <ul className="pagination pagination-lg">
                                <li className="page-item">
                                    <a className="page-link" href="/index.html">
                                        <i className="fas fa-angle-left"></i>
                                    </a>
                                </li>
                                <li className="page-item"><a className="page-link" href="/index.html">1</a></li>
                                <li className="page-item active"><a className="page-link" href="/index.html">2</a></li>
                                <li className="page-item"><a className="page-link" href="/index.html">3</a></li>
                                <li className="page-item"><a className="page-link" href="/index.html">4</a></li>
                                <li className="page-item"><a className="page-link" href="/index.html">5</a></li>
                                <li className="page-item">
                                    <a className="page-link" href="/index.html">
                                        <i className="fas fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    }
                </div>
            )
        }
    }
}

export default Offers;
