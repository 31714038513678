import React from 'react'
import moment from 'moment';
import {DateRangePicker as ReactDateRangePicker} from "react-bootstrap-daterangepicker";

import {consoleLog} from "../../components/console";

import 'bootstrap-daterangepicker/daterangepicker.css';

export default function DateRangePicker({dates, updateDates}) {
    const handleCalendarApply = function (event, picker) {
        consoleLog(event, picker);

        consoleLog(picker.startDate.format('YYYY-MM-DD'))
        consoleLog(picker.endDate.format('YYYY-MM-DD'))

        updateDates({
            start: picker.startDate.format('YYYY-MM-DD'),
            end: picker.endDate.format('YYYY-MM-DD'),
        })
    }

    return (
        <>
            {/*<pre>{JSON.stringify(dates, null, 2)}</pre>*/}
            <ReactDateRangePicker
                onApply={handleCalendarApply}
                initialSettings={{
                    locale: {
                        format: 'YYYY-MM-DD'
                    },
                    startDate: dates.start,
                    endDate: dates.end,
                    alwaysShowCalendars: true,
                    ranges: {
                        'Today': [
                            moment().toDate(),
                            moment().toDate(),
                        ],
                        'Yesterday': [
                            moment().subtract(1, 'days').toDate(),
                            moment().subtract(1, 'days').toDate(),
                        ],
                        'Last 7 Days': [
                            moment().subtract(6, 'days').toDate(),
                            moment().toDate(),
                        ],
                        'Last 30 Days': [
                            moment().subtract(29, 'days').toDate(),
                            moment().toDate(),
                        ],
                        'This Month': [
                            moment().startOf('month').toDate(),
                            moment().endOf('month').toDate(),
                        ],
                        'Last Month': [
                            moment().subtract(1, 'month').startOf('month').toDate(),
                            moment().subtract(1, 'month').endOf('month').toDate(),
                        ],
                    },
                }}
            >
                <input type="text" className="form-control  form-control-lg"/>
            </ReactDateRangePicker>
        </>
    )
}

DateRangePicker.defaultProps = {
    dates: {
        start: moment().subtract(6, 'days'),
        end: moment(),
    },
    // error: {
    //     message: "Что-то пошло не так"
    // },
}
