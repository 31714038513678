// let userPlugin;

// function ajax() {
// 	return webix.ajax().headers({
// 		"Accept": "application/json",
// 		"Content-Type": "application/json",
// 		"Authorization": "Bearer " + getToken(),
// 	});
// }

// function get(url) {
// 	return ajax().get(url)
// 		.fail((XMLHttpRequest) => {
// 			if (XMLHttpRequest.status === 401) {
// 				let scope = webix.$$("layout:main").$scope;
//
// 				let user = getUserPlugin();
// 				user.setUser(null);
//
// 				scope.show("/top/login");
// 			}
//
// 			// console.log("this", this);
// 			// console.log("webix", webix);
// 			// console.log("XMLHttpRequest", XMLHttpRequest);
//
// 			return webix.promise.reject(XMLHttpRequest);
// 		});
// }
//
// function create(url, data) {
// 	return ajax().post(url, JSON.stringify(data));
// }
//
// function update(url, data) {
// 	return ajax().put(url, JSON.stringify(data));
// }
//
// function remove(url) {
// 	return ajax().del(url);
// }
//
// function getUserPlugin() {
// 	// console.log("userPlugin", userPlugin);
//
// 	if (!userPlugin) {
// 		userPlugin = webix.$$("layout:main").$scope.app.getService("user");
// 		// console.log("userPlugin", userPlugin);
// 	}
//
// 	return userPlugin;
// }
//
function getToken() {
	const token = localStorage.getItem('token')

	// let user = webix.storage.local.get("user_data");
	// if (!user) {
	// 	return "";
	// }
	//
	// return user.token;
	// return getUserPlugin().getToken();

	return token;
}

export default {
	// ajax,
	// get,
	// create,
	// update,
	// remove,
	getToken
};
