import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Tab from "./Tab";
import ButtonSubmit from "../../components/ButtonSubmit";

export default function TabPayments({tabActiveID}) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    return (
        <Tab tabID="payments" tabActiveID={tabActiveID} isLoading={isLoading} title="Payments">
            <form>
                <div className="form-group">
                    <label htmlFor="inputPayments">Payments Type</label>
                    <select id="inputPayments" className="form-control" defaultValue="0">
                        <option value="0">Choose...</option>
                        <option value="1">...</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="inputAccountNumber">Account Number</label>
                    <input type="text" className="form-control" id="inputAccountNumber"
                           placeholder=""/>
                </div>

                <ButtonSubmit text="Save changes"/>
            </form>
        </Tab>
    )
}

TabPayments.propTypes = {
    tabActiveID: PropTypes.string.isRequired,
}
