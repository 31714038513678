import React, {useState, useEffect} from "react";
import styled from 'styled-components'

const Styled = styled.div`
  label {
    margin-right: 10px;
  }
  button {
    margin-right: 10px;
  }
  button:last-child {
    margin-right: 0;
  }
`

export default function FilterGroupBy({items, value, updateValue}) {
    const [currentVal, setCurrentVal] = useState(value);

    useEffect(() => {
        updateValue(currentVal);
    }, [currentVal]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Styled>
            <label className="form-label">Group by:</label>
            {items.filter(i => i.visible !== false).map(item => (
                <button
                    key={item.key}
                    className={"btn " + (item.key === currentVal ? "btn-primary" : "btn-outline-primary")}
                    onClick={() => setCurrentVal(item.key)}
                >{item.title}</button>
            ))}
        </Styled>
    )
}