import {useState} from "react";

export const useInput = initialValue => {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState('');

    return {
        value,
        setValue,
        error,
        setError,
        reset: () => setValue(""),
        bind: {
            value: value,
            onChange: event => {
                const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
                setValue(value)
            }
        },
    }
}

export const useInputRadio = initialValue => {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState('');

    return {
        value,
        setValue,
        error,
        setError,
        reset: () => setValue(""),
        bind: function (v) {
            return {
                value: v,
                checked: v === value,
                onChange: event => {
                    const vvv = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
                    setValue(vvv)
                }
            }
        },
    }
}
