import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Tab from "./Tab";
import ButtonSubmit from "../../components/ButtonSubmit";

export default function TabPassword({tabActiveID}) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    return (
        <Tab tabID="password" tabActiveID={tabActiveID} isLoading={isLoading} title="Password">
            <form>
                <div className="form-group">
                    <label htmlFor="inputPasswordCurrent">Current password</label>
                    <input type="password" className="form-control"
                           id="inputPasswordCurrent"/>
                    <small><a href="/index.html">Forgot your password?</a></small>
                </div>

                <div className="form-group">
                    <label htmlFor="inputPasswordNew">New password</label>
                    <input type="password" className="form-control"
                           id="inputPasswordNew"/>
                </div>

                <div className="form-group">
                    <label htmlFor="inputPasswordNew2">Verify password</label>
                    <input type="password" className="form-control"
                           id="inputPasswordNew2"/>
                </div>

                <ButtonSubmit text="Save changes"/>
            </form>
        </Tab>
    )
}

TabPassword.propTypes = {
    tabActiveID: PropTypes.string.isRequired,
}
