import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import {consoleLog} from "../../components/console";

export default function GTag() {
    const { pathname } = useLocation();

    useEffect(() => {
        consoleLog("pathname", pathname)

        // window.gtag('config', 'G-3NV4HMDGY0', {'page_path': pathname});
        window.dataLayer.push(['config', 'G-3NV4HMDGY0', {'page_path': pathname}]);
    }, [pathname]);

    return null;
}
