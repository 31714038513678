import React from 'react';

class Logout extends React.Component {
    componentDidMount() {
        localStorage.removeItem('token')

        window.location.reload();
    }

    render() {
        return <div></div>
    }
}

export default Logout;
