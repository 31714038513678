import React, {useEffect, useState} from 'react'
import Select from "react-select";

export default function SelectCountry2({value, updateValue, options, defaultLabel}) {
    const cv = {value: value, label: defaultLabel};
    let hasEmpty = false;
    options.forEach((e) => {
        if (e.value === value) {
            cv.label = e.label;
        }
        if (e.value === "") {
            hasEmpty = true;
        }
    })
    if (!hasEmpty && cv.value !== "") {
        options.unshift({value : "", label : defaultLabel});
    }

    const [currentVal, setCurrentVal] = useState(cv);

    useEffect(() => {
        updateValue(currentVal.value);
    }, [currentVal]); // eslint-disable-line react-hooks/exhaustive-deps

    const styles = {
        container: base => ({
            ...base,
            flex: 1
        })
    };

    return (
        <Select
            styles={styles}
            // className="form-control mb-3"
            name={"filter_country"}
            options={options}
            // isMulti={true}
            clearable={true}
            value={currentVal}
            onChange={e => setCurrentVal(e)}
        />
    )
}
